import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const couleeImg = "../images/process/coulee.png";
const infusionImg = "../images/process/infusion-1.png";
const enroulementImg = "../images/process/enroulement.png";
const stratificationImg = "../images/process/stratification.png";

const processes = [
  {
    id: 0,
    title: "Coulée",
    description:
      "Le processus de coulée est une méthode de fabrication qui implique la versée de résine liquide dans un moule (Silicone, Latex ou autre) pour créer des pièces composites. Cette technique permet la production de pièces aux formes complexes avec une grande précision. Carthage Composites Distribution possède une vaste expérience dans la maîtrise de ce processus, offrant ainsi une large gamme de produits pour différentes applications.",
    image: "../images/process/coulee.jpg",
    alt: "",
  },
  {
    id: 1,
    title: "Infusion",
    description:
      "L'infusion est une méthode avancée de fabrication de composites qui implique l'injection de résine dans un moule contenant les renforts (fibres) à l'aide d'un vide contrôlé. Cette technique permet d'obtenir des pièces à la fois légères et résistantes. Carthage Composites Distribution excelle dans la maîtrise de ce processus complexe, fournissant des produits infusés de manière précise pour des secteurs exigeants tels que l'aéronautique, l'automobile et la construction navale.",
    image: "../images/process/infusion-1.jpg",
    alt: "",
  },
  {
    id: 2,
    title: "Enroulement",
    description:
      "Le processus d'enroulement est une méthode qui consiste à appliquer des couches continues de renforts, autour d'un mandrin. Ce processus est idéal pour la fabrication de réservoirs, de mâts de bateaux, de pipelines, de silos, de tuyaux, de poteaux et d'autres produits nécessitant une résistance élevée, avec tout types de résine vinylester, epoxy ou autre. Carthage Composites Distribution a acquis une expertise solide offrant une panoplie de produits dans ce process.",
    image: "../images/process/enroulement.jpg",
    alt: "",
  },
  {
    id: 3,
    title: "Stratification",
    description:
      "La stratification est le processus de superposition de couches de renforts (fibres de verre ou roving et complexe) avec de la résine pour créer des pièces composites. Cette méthode permet de fabriquer des pièces avec des propriétés spécifiques en ajustant la disposition des couches et le type de renfort utilisé. Carthage Composites Distribution est reconnue pour son expertise dans la stratification, garantissant des produits adaptés aux besoins, dans la fabrication de pièces structurelles ou de pièces légères et résistantes.",
    image: "../images/process/stratification.jpg",
    alt: "",
  },
];

const Process = () => {
  return (
    <Layout>
      <Seo title="Process" />
      <div className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto text-center">
              <h2 className="title">Nos Process</h2>
              <h5 className="description">
                <b>
                  Dans notre engagement à fournir des produits composites de
                  qualité supérieure, Carthage Composites Distribution met en
                  œuvre une expertise pointue dans une variété de processus de
                  fabrication. Chaque étape de notre processus est soigneusement
                  exécutée pour garantir la création de pièces composites
                  durables et performantes. Découvrez ci-dessous un aperçu de
                  quelques-uns de nos processus clés qui nous permettent de
                  répondre aux besoins variés de nos clients.
                </b>
              </h5>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-5">
        <div className="container pb-5">
          <div className="row py-5">
            <div className="col-md-6 order-md-2">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={couleeImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-6 order-md-1">
              <h3 className="text-danger text-center">
                <b>{processes[0].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{processes[0].description}</b>
              </h5>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-md-6">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={infusionImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-6">
              <h3 className="text-danger text-center">
                <b>{processes[1].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{processes[1].description}</b>
              </h5>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-md-6 order-md-2">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={enroulementImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-6 order-md-1">
              <h3 className="text-danger text-center">
                <b>{processes[2].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{processes[2].description}</b>
              </h5>
            </div>
          </div>

          <div className="row py-5">
            <div className="col-md-6">
              <StaticImage
                // alt="Logo Carthage Composites Distribution"
                src={stratificationImg}
                loading="eager"
                quality={100}
              />
            </div>
            <div className="col-md-6">
              <h3 className="text-danger text-center">
                <b>{processes[3].title}</b>
              </h3>
              <h5 className="description pt-3">
                <b>{processes[3].description}</b>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Process;
